import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import starIcon from '../../assets/images/fav-a.svg'
import starIcon2 from '../../assets/images/fav-b.svg'

const query = graphql`
{
    strapiPartner {
        partner {
            id
            image {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
}
` 

const Partner = () => {

    const data = useStaticQuery(query)
    const {strapiPartner: {
        partner
    }} = data 

    return (
        <div className="partner-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        Partners
                        <img src={starIcon2} alt="testimonial" /> 
                    </span>
                </div>
                <div className="row align-items-center justify-content-around">
                    {partner.map(item => (
                        <div className="col-lg-2 col-6 col-sm-4 col-md-4" key={item.id}>
                            <div className="single-partner-item">
                                <img 
                                    src={item.image.childImageSharp.fluid.src} 
                                    alt="Partner Image" 
                                />
                            </div>
                        </div> 
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Partner